import React, {Fragment, useEffect} from 'react';
import Introduction from './../components/Introduction';
import About from './../components/About';
import Experience from './../components/Experience';
import Projects from './../components/Projects';
import Contact from './../components/Contact';
import Aos from 'aos';
import "aos/dist/aos.css";

const HomeScreen = () => {

    useEffect(()=>{
        Aos.init({duration: 2000});
    }, [])

    return (
        <Fragment>
            <Introduction/>
            <div data-aos="fade-up">
                <About/>
            </div>
            <div data-aos="fade-up">
                <Experience/>
            </div>
            <div data-aos="fade-up">
                <Projects/>
            </div>
            <div data-aos="fade-up">
                <Contact/>
            </div>
        </Fragment>
    )
}

export default HomeScreen
