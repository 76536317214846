import React, { useState, Fragment } from 'react'

const Experience = () => {
    const titles = ["TMK", "Freshbooks", "Idea Theorem"];
    const [selectedExperience, setSelectedExperience] = useState(0);
    
    const jobDescriptions = [
        {
            title: "Full Stack Developer",
            company: "TMK Inc.",
            dates: ["Jan 2024 - Present"],
            highlights: [
                "Designed and developed a backend API using ASP.NET, optimizing system performance with caching techniques. Integrated AWS S3 for efficient data storage and retrieval, leveraging modern cloud technologies.",
                "Developed responsive web pages for the core platform utilizing React, incorporating React Hooks for effective state management. Implemented Redux for global state handling, optimizing UI consistency and performance.",
                "Enhanced system security by using Https-only cookies for authentication, replacing standard JWT cookie transfers. This mitigated the risk of XSS attacks, fortifying the platform's security infrastructure.",
            ]    
        },
        {
            title: "Full Stack Developer",
            company: "Freshbooks",
            dates: ["Jan 2023 - Dec 2023"],
            highlights: [
                "Decomposed the Journal Entry component from a monolithic structure to a Python-based microservice architecture, integrating gRPC to achieve a 5 times boost in system speed compared to the initial REST-based setup.",
                "Implemented key frontend features such as a journal entry form, an advanced filter/search table, and a journal entries view page, all designed to interact with the microservice.",
                "Reduced post-deployment bugs by implementing testing protocols, including unit, integration, and E2E tests.",
                "Boosted the platform’s transaction capabilities through the integration of WePay payment API, offering users a more flexible and cost-efficient payment solution.",
                "Developed front-end features, including a standout WePay payment modal for easy activation and deactivation of services, and ensured their reliability by writing comprehensive tests.",
                "Conducted over 30 peer code reviews, ensuring high coding standards and efficiency."
            ]
        },
        {
            title: "Full Stack Developer",
            company: "Idea Thoerem",
            dates: ["April 2022 - Dec 2022"],
            highlights: [
                "Developed frontend components, including question filter/search for students, a teacher/course administration portal, and site navigation, alongside core platform pages to enhance user experience and site functionality.",
                "Increased the platform’s performance by over 500% by migrating the sorting operations of a dataset with more than 50,000 questions from the backend to the frontend. This strategy capitalized on the infrequency of updates, streamlining the filtering processes and reducing the loading spinner time by 2.5 seconds.",
                "Accelerated project timelines by 8% through rapid and bug-free translation of Figma designs into functional features, demonstrating exceptional task completion speed.",
                "Implemented four frontend key features in a real estate transactions platform, efficiently resolving assigned tasks and contributing to the advancement of the project’s sprint schedule by 5%.",
                "Resolved over 20 defects and actively participated in the accessibility evaluation of the platform, ensuring enhanced usability and compliance with accessibility standards."
            ]
        },
    ]

    return (
        <Fragment>
            <div className="flex flex-col mini_screen" id="experience">
                <div className="flex">
                    <div className="robot text-4xl text-pink">02.&nbsp;</div>
                    <h2 className="anton font-bold text-4xl text-light">Experience</h2>
                </div>
                <div className="pt-7 flex flex-col sm:flex-col lg:flex-row" id="">
                    <div className="flex flex-row sm:flex-row lg:flex-col pr-5">
                        {
                            titles.map((title, index) => {
                                const isSelected = index === selectedExperience;
                                const buttonClasses = `text-pink robot button-clicked button font-semibold text-xl ${isSelected ? 'clicked' : ''}`;
                                return (
                                    <button 
                                        key={index} 
                                        className={buttonClasses} 
                                        onClick={() => setSelectedExperience(index)}
                                    >
                                        <div className="pl-5 py-2 pr-5">{title}</div>
                                    </button>
                                )
                            })
                        }
                    </div>
                    <div className="pt-2">
                        <div className="flex" id="experience_job_title">
                            <h3 className="anton font-bold text-light text-xl">{jobDescriptions[selectedExperience].title} At &nbsp;</h3>
                            <h3 className="text-xl anton font-bold text-pink">{jobDescriptions[selectedExperience].company}</h3>
                        </div>
                        <div className="py-1 flex flex-col">
                            {
                                jobDescriptions[selectedExperience].dates.map(date => {
                                    return <h4 className="robot text-light-green pt-1">{date}</h4>
                                })
                            }
                        </div>
                        <div className="pt-2 text-light-green anton">
                            <ul>
                                {
                                    jobDescriptions[selectedExperience].highlights.map(highlight => {
                                        return <li className="flex"><i class="fas fa-caret-right pr-3 text-pink pt-1"></i>{highlight}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Experience
