import React, {Fragment, useState, useEffect} from 'react'
import { Container } from 'react-bootstrap'

const NavBar = () => {
    
    const [show, setShow] = useState(true);

    const [showMobile, setShowMobile] = useState(false);

    // const resumeLink = "https://drive.google.com/file/d/1Ixm8OZQ_k1fSMD057btOX7RBGtcmYnKm/view?usp=sharing"

    useEffect(() => {
        let scrollPos = 0;
        // adding scroll event
        window.addEventListener('scroll', function(){
          // detects new state and compares it with the new one
        if ((document.body.getBoundingClientRect()).top > scrollPos){
            setShow(true);
        } else{
            setShow(false);
        }
        // saves the new position for iteration.
        scrollPos = (document.body.getBoundingClientRect()).top;
        });
    }, []);

    
    useEffect(()=>{
        if(showMobile===true){
            document.getElementById('body').classList.add('overflow-hidden');
        }
        else{
            document.getElementById('body').classList.remove('overflow-hidden');
        }
    },[showMobile])


    function menuToggle(){
        setShowMobile(!showMobile);
        blurring();
    }

    function menuClick(){
        setShowMobile(!showMobile);
        blurring();
    }

    const [blur, setBlur] = useState(false);

    function blurring(){
      setBlur(!blur);
    }

    return (
        <Fragment>
            <div id="navbar" className={show ? "show" : "hide"}>
                <div id="navbar_cover" className={show ? "show w-full" : "hide w-full"}></div>
                    <Container>
                        <div className="flex items-center h-full" id="menu">
                            <a href="/" className="text-3xl anton font-bold text-pink">J</a>
                            <div className="ml-auto flex text-light text-xl robot items-center" id="sub_menu">
                                <a href="/#about">
                                    <div className="px-7" id="nav_links">About</div>
                                </a>
                                <a href="/#experience">
                                    <div className="px-7" id="nav_links">Experience</div>
                                </a>
                                <a href="/#projects">
                                    <div className="px-7" id="nav_links">Projects</div>
                                </a>
                                <a href="/#contact">
                                    <div className="px-7" id="nav_links">Contact</div>
                                </a>
                                {/* <button className="px-7 border rounded py-1 text-pink" id="pink_button">
                                    <a href={resumeLink} target="_blank" rel="noopener noreferrer">Resume</a>
                                </button> */}
                            </div>
                            <div className="ml-auto" id="hamburger" onClick={menuToggle}>
                                <i class="fas fa-bars text-pink text-4xl"></i>
                            </div>
                        </div>
                    </Container>
            </div>
            <div id="mobile_menu" className={showMobile ? "grid show" : "grid"}>
                <a href="/#about">
                    <div className="py-7 text-light robot text-2xl" id="nav_links" onClick={menuClick}>About</div>
                </a>
                <a href="/#experience">
                    <div className="py-7 text-light robot text-2xl" id="nav_links" onClick={menuClick}>Experience</div>
                </a>
                <a href="/#projects">
                    <div className="py-7 text-light robot text-2xl" id="nav_links" onClick={menuClick}>Projects</div>
                </a>
                <a href="/#contact">
                    <div className="py-7 text-light robot text-2xl" id="nav_links" onClick={menuClick}>Contact</div>
                </a>
                {/* <div className="py-7">
                    <button className="px-7 border rounded py-1 text-2xl text-pink" id="pink_button">
                        <a href={resumeLink} target="_blank" rel="noopener noreferrer" onClick={menuClick}>Resume</a>
                    </button>
                </div> */}
            </div>
            <div className={blur ? "blur" : ""} onClick={menuToggle}></div>
        </Fragment>
    )
}

export default NavBar
