import React, {Fragment} from 'react'

const Contact = () => {
    return (
        <Fragment>
            <div className="flex flex-col mini_screen" id="contact">
                <div className="flex ">
                    <div className="robot text-4xl text-pink">04.&nbsp;</div>
                    <h2 className="anton font-bold text-4xl text-light">Contacts</h2>
                </div>
                <p></p>
                <div className="pt-7">
                    <div className="flex flex-col" id="box">
                        <div className="flex pt-5 pb-2 px-5">
                            <i class="fas fa-envelope-square text-xl text-pink"></i>
                            <h3 className="text-light robot text-xl font-semibold">&nbsp;&nbsp;jykangdev@gmail.com</h3>
                        </div>
                        <div className="flex px-5 pb-2">
                            <i class="fas fa-phone text-pink text-xl"></i>
                            <h3 className="text-light robot text-xl font-semibold">&nbsp;&nbsp;(647)-833-4325</h3>
                        </div>
                        <div className="flex px-5 pb-2">
                            <i class="fab fa-linkedin text-pink text-xl"></i>
                            <h3 className="text-light robot text-xl font-semibold">&nbsp;&nbsp;<a href="https://www.linkedin.com/in/jay-kang-039858221/" target="_blank" rel="noopener noreferrer">LinkedIn</a></h3>
                        </div>
                        <div className="flex pb-5 px-5">
                            <i class="fab fa-github text-pink text-xl"></i>
                            <h3 className="text-light robot text-xl font-semibold">&nbsp;&nbsp;<a href="https://github.com/jykang96" target="_blank" rel="noopener noreferrer">Github</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Contact
