import React, {Fragment} from 'react'

const Introduction = () => {

    return (
        <Fragment>
            <div className="flex flex-col animation screen" id="introduction">
                <p className="text-pink text-xl robot pb-5">Hello, my name is</p>
                <h1 className="text-light anton text-8xl font-bold pb-4">Jay Kang.</h1>
                <h2 className="text-7xl font-bold anton text-darker pb-5">I love building things for the web.</h2>
                <h3 className="pt-3 text-xl anton text-light-green">I am a Full Stack Developer and UI/UX enthusiast. I have a great passion for designing beautiful and functional user experiences. I want to make things that make a difference.</h3>
            </div>
        </Fragment>
    )
}

export default Introduction
