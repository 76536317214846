import React, {Fragment} from 'react'

const Projects = () => {
    return (
        <Fragment>
            <div className="flex flex-col screen" id="projects">
                <div className="flex">
                    <div className="robot text-4xl text-pink">03.&nbsp;</div>
                    <h2 className="anton font-bold text-4xl text-light" id="heading">Projects I've Developed</h2>
                </div>
                <div className="pt-7 grid grid-cols-2 pb-5" id="projects_container">
                    <div className="flex flex-col self-center">
                        <div className="text-pink robot text-md pb-1" id="web_title">Featured Project</div>
                        <h2 className="text-xl anton font-bold text-light" id="web_title">Freshbooks</h2>
                        <div className="pt-4">
                            <div className="description shadow-xl">
                                <p className="text-light-green anton px-5 py-5">In my role at FreshBooks, I enhanced the platform's transaction capabilities by integrating the WePay payment API, streamlining payment processes for users. Additionally, I developed front-end features and conducted peer code reviews to ensure coding standards and efficiency. Moreover, I decomposed a monolithic structure into a Python-based microservice architecture, resulting in a significant speed boost. Finally, I implemented key EmberJS frontend features and testing protocols to reduce post-deployment bugs.</p>
                            </div>
                        </div>
                        <div className="flex pt-5 robot text-pink" id="flex-centering">
                            <p className="pr-2">EmberJS</p>
                            <p className="px-2">Flask</p>
                            <p className="px-2">gRPC</p>
                            <p className="px-2">JavaScript</p>
                            <p className="px-2">Python</p>
                        </div>
                        <a href="https://www.freshbooks.com/" target="_blank" rel="noopener noreferrer" id="flex-centering">
                        <i class="fas fa-link text-pink pt-3 text-3xl icon"></i>
                        </a>
                    </div>
                    <div className="grid flex h-full items-center justify-center w-full">
                        <div id="mobile_title">
                            <div className="text-pink robot text-md pb-1">Featured Project</div>
                            <h2 className="text-xl anton font-bold text-light">Freshbooks</h2>
                        </div>
                        <img src="/images/freshbooks.png" alt="freshbooks" className="pl-4 cover" id="project_image"/>
                    </div>
                </div>
                <div className="pt-10 grid grid-cols-2 pb-5" id="projects_container_reverse">
                    <div className="grid flex h-full items-center justify-center w-full">
                        <div id='mobile_title'>
                            <div className="text-pink robot text-md pb-1">Featured Project</div>
                            <h2 className="text-xl anton font-bold text-light">Chicago Title Canada</h2>
                        </div>
                        <div className="flex items-center">
                            <img src="/images/title_chicago.png" alt="tital_chicago" className="pl-4 cover h-full" id="project_image"/>
                        </div>
                    </div>
                    <div className="flex flex-col self-center">
                        <div className="text-pink robot text-md pb-1" id="web_title">Featured Project</div>
                        <h2 className="text-xl anton font-bold text-light" id="web_title">Chicago Title Canada</h2>
                        <div className="pt-4">
                            <div className="description shadow-xl">
                                <p className="text-light-green anton px-5 py-5">Chicago Title, a trusted leader in Canada's real estate industry for over 70 years, prioritizes client peace of mind by offering unparalleled expertise in local legislation and personalized customer care. As part of Fidelity National Financial, North America's largest title insurer, they excel in title insurance solutions and real estate technology. My role involved developing dynamic forms and core pages using React, ensuring seamless user experiences and efficient data management to support Chicago Title's commitment to client success and innovation in the industry.</p>
                            </div>
                        </div>
                        <div className="flex pt-5 robot text-pink" id="flex-centering">
                            <p className="pr-2">React</p>
                            <p className="pr-2">NodeJS</p>
                            <p className="px-2">JavaScript</p>
                            <p className="px-2">TailwindCSS</p>
                        </div>
                        <div className="flex gap-5" id="flex-centering">
                            <a href="https://chicagotitle.ca/" target="_blank" rel="noopener noreferrer">
                                <i class="fas fa-link text-pink pt-3 text-3xl icon"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="pt-7 grid grid-cols-2 pb-5" id="projects_container">
                    <div className="flex flex-col self-center">
                        <div className="text-pink robot text-md pb-1" id="web_title">Featured Project</div>
                        <h2 className="text-xl anton font-bold text-light" id="web_title">Bloomsie</h2>
                        <div className="pt-4">
                            <div className="description shadow-xl">
                                <p className="text-light-green anton px-5 py-5">I contributed to the development of Bloomsie's website, focusing on the login/signup process and core pages. I ensured a seamless user experience with intuitive authentication methods and security measures. The core pages showcase mesmerizing time-lapse videos of floral arrangements, complemented by customizable greetings or invitations, reflecting Bloomsie's innovative approach to flower gifting. My aim was to make the website engaging and user-friendly, amplifying Bloomsie's mission of accessibility, sustainability, and unforgettable experiences in flower gifting.</p>
                            </div>
                        </div>
                        <div className="flex pt-5 robot text-pink" id="flex-centering">
                            <p className="pr-2">React</p>
                            <p className="pr-2">NodeJS</p>
                            <p className="px-2">JavaScript</p> 
                            <p className="px-2">TailwindCSS</p>
                        </div>
                        <a href="https://www.bloomsie.com/" target="_blank" rel="noopener noreferrer" id="flex-centering">
                        <i class="fas fa-link text-pink pt-3 text-3xl icon"></i>
                        </a>
                    </div>
                    <div className="grid flex h-full items-center justify-center w-full">
                        <div id='mobile_title'>
                            <div className="text-pink robot text-md pb-1">Featured Project</div>
                            <h2 className="text-xl anton font-bold text-light">Text-Based RPG Game</h2>
                        </div>
                        <img src="/images/bloomsie.png" alt="bloomsie" className="pl-4 cover" id="project_image"/>
                    </div>
                </div>
                <div className="pt-10 grid grid-cols-2 pb-5" id="projects_container_reverse">
                    <div className="grid flex h-full items-center justify-center w-full">
                        <div id='mobile_title'>
                            <div className="text-pink robot text-md pb-1">Featured Project</div>
                            <h2 className="text-xl anton font-bold text-light">Chicago Title Canada</h2>
                        </div>
                        <div className="flex items-center">
                            <img src="/images/genius_forest.png" alt="tital_chicago" className="pl-4 cover h-full" id="project_image"/>
                        </div>
                    </div>
                    <div className="flex flex-col self-center">
                        <div className="text-pink robot text-md pb-1" id="web_title">Featured Project</div>
                        <h2 className="text-xl anton font-bold text-light" id="web_title">Genius Forest</h2>
                        <div className="pt-4">
                            <div className="description shadow-xl">
                                <p className="text-light-green anton px-5 py-5">Genius Forest revolutionizes learning by providing personalized plans tailored to individual skill levels, utilizing a diagnostic system to assess abilities and recommend improvements. Parents and students can monitor progress through live reporting. My contributions to Genius Forest included enhancing user engagement by 60% through adaptive backend features, optimizing platform performance by over 500% with frontend data migration, and streamlining project timelines by efficiently translating design concepts into high-quality features, ensuring timely updates and seamless integration of team decisions.</p>
                            </div>
                        </div>
                        <div className="flex pt-5 robot text-pink" id="flex-centering">
                            <p className="pr-2">React</p>
                            <p className="pr-2">NodeJS</p>
                            <p className="px-2">JavaScript</p>
                            <p className="px-2">SCSS</p>
                        </div>
                        <div className="flex gap-5" id="flex-centering">
                            <a href="https://www.geniusforest.ca/" target="_blank" rel="noopener noreferrer">
                                <i class="fas fa-link text-pink pt-3 text-3xl icon"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Projects
