import React, {Fragment, Suspense, useState, useEffect } from 'react';
import './App.css';
import NavBar from './components/NavBar';
import {Container} from 'react-bootstrap';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import HomeScreen from './screens/HomeScreen';

function App() {

  const [loading, setLoading] = useState(false);
  

  useEffect(()=>{
    setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    }, 2500);
  }, [])

  // const [blur, setBlur] = useState(false);

  // function blurring(){
  //   setBlur(!blur);
  // }
  

  return (
    <Fragment>
      {
        loading ? 

        <div id="loader" className="flex justify-center animation text-pink anton font-bold text-9xl">
          <div className="self-center ">
            Welcome!
          </div>
        </div>
        :

        <Router>
        <NavBar/>
        <main className="">
          <Container>
            <div className="page_content">
            <Suspense fallback={<p>Loading...</p>}>
                <Switch>
                  <Route path='/' component={HomeScreen} exact/>
                </Switch>
            </Suspense>
            </div>
          </Container>
        </main>
      </Router>
      }
    </Fragment>
  );
}

export default App;
