import React, {Fragment} from 'react';

const About = () => {
    return (
        <Fragment>
            <div className="screen" id="about">
                <div className="flex">
                    <div className="robot text-4xl text-pink">01.&nbsp;</div>
                    <h2 className="anton font-bold text-4xl text-light">About Me</h2>
                </div>
                <div className="pt-7 grid grid-cols-2" id="about_container">
                    <div>
                        <p className="text-light-green text-xl pb-1">Hello! my name is Jay and I love being creative as a web developer. My fascination for web development started back in 2013 when social media platforms like Instagram started to gain mass popularity.</p>
                        <p className="text-light-green text-xl pb-1">Ever since I started coding, I've had the privilage to learn many things about web development. I aim to use these sets of learned skills to build something brilliant and be part of projects that are greater than me.</p>
                        <p className="text-light-green text-xl font-semibold py-1">Here are a few things I've been working with recently:</p>
                        <div className="grid grid-cols-2">
                            <ul className="text-light-green robot pt-1">
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>JavaScript</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>Node.js</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>Ember.js</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>Flask</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>PostgreSQL</li>
                            </ul>
                            <ul className="text-light-green robot pt-1">
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>React</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>Express</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>MongoDB</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>ASP.NET</li>
                                <li><i class="fas fa-caret-right pr-3 text-pink"></i>MySQL</li>
                            </ul>
                        </div>
                    </div>
                    <img src="/images/me.jpg" alt="me" className="pl-4"/>
                </div>
            </div>
        </Fragment>
    )
}

export default About
